import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SocketEventTypeEnum } from "../enums";
import { Room, UserMessage } from "../services";
import { useChatContext } from "./ChatContext";
import { useConnectionContext } from "./ConnectionContext";

const MessageContext = createContext<{
  messageListByRoom: Record<string, UserMessage[]>;
  setMessageListByRoom: (value: Record<string, UserMessage[]>) => void;
  selectedThread: UserMessage | undefined;
  setSelectedThread: (value: UserMessage) => void;
  loadingMessages: Record<string, boolean>;
  setLoadingMessages: (value: Record<string, boolean>) => void;
  currentMessagesList: UserMessage[];
  canLoadMore: boolean;
  setCanLoadMore: (value: boolean) => void;
}>({
  messageListByRoom: {},
  setMessageListByRoom: () => {},
  selectedThread: undefined,
  setSelectedThread: () => {},
  loadingMessages: {},
  setLoadingMessages: () => {},
  currentMessagesList: [],
  canLoadMore: true,
  setCanLoadMore: () => {},
});

export const MessageContextProvider = ({ children }: PropsWithChildren) => {
  const [messageListByRoom, setMessageListByRoom] = useState<
    Record<string, UserMessage[]>
  >({});
  const [selectedThread, setSelectedThread] = useState<UserMessage>();
  const [loadingMessages, setLoadingMessages] = useState({});
  const [canLoadMore, setCanLoadMore] = useState(true);

  const { selectedRoom, rooms } = useChatContext();
  // const { channel } = useChannelContext();
  // const { roomEncryptKeys } = useKeysStore();
  const { socketReceivedData } = useConnectionContext();
  const roomsRef = useRef<Room[]>();
  const messageListByRoomRef = useRef<typeof messageListByRoom>();
  // const roomEncryptKeysRef = useRef<Record<string, string>>({});
  const loadingMessageRef = useRef<typeof loadingMessages>();

  const currentMessagesList = useMemo(
    () => (selectedRoom?.id ? messageListByRoom?.[selectedRoom?.id] ?? [] : []),
    [selectedRoom?.id, messageListByRoom]
  );

  const selectedRoomRef = useRef<Room>();
  const currentMessagesListRef = useRef<typeof currentMessagesList>();

  useEffect(() => {
    selectedRoomRef.current = selectedRoom;
  }, [selectedRoom]);

  useEffect(() => {
    currentMessagesListRef.current = currentMessagesList;
  }, [currentMessagesList]);

  useEffect(() => {
    if (selectedRoom?.id !== selectedRoomRef.current?.id) {
      setSelectedThread(undefined);
    }
  }, [selectedRoom]);

  useEffect(() => {
    messageListByRoomRef.current = messageListByRoom;
  }, [messageListByRoom]);

  useEffect(() => {
    roomsRef.current = rooms;
  }, [rooms]);

  useEffect(() => {
    loadingMessageRef.current = loadingMessages;
  }, [loadingMessages]);

  // const channelRef = useRef<typeof channel>({});

  // useEffect(() => {
  //   channelRef.current = channel;
  // }, [channel]);

  // useEffect(() => {
  //   roomEncryptKeysRef.current = roomEncryptKeys;
  // }, [roomEncryptKeys]);

  // const { checkIsRoomP2PConnected } = useRoomHelper();
  // const { handleJoinWebRTCRoom } = useRTCContext();

  useEffect(() => {}, [selectedRoom]);

  useEffect(() => {
    const lastPackage = socketReceivedData[socketReceivedData.length - 1];

    if (
      (canLoadMore && selectedRoom?.connectionType === "p2p") ||
      selectedRoom?.encryptType === "key_exchange"
    ) {
      setCanLoadMore(false);
      return;
    }

    if (
      (lastPackage?.type === SocketEventTypeEnum.messages ||
        lastPackage?.type === SocketEventTypeEnum.messageAdded) &&
      lastPackage?.roomId === selectedRoom?.id
    ) {
      if (lastPackage.total <= currentMessagesList?.length) {
        setCanLoadMore(false);
      } else {
        setCanLoadMore(true);
      }
    }
  }, [socketReceivedData, selectedRoom]);

  // const { roomKeyExchangeStatus } = useKeyExchange();
  // const { canSendMessage } = useCreateMessageContext();

  return (
    <MessageContext.Provider
      value={{
        messageListByRoom,
        setMessageListByRoom,
        selectedThread,
        setSelectedThread,
        loadingMessages,
        setLoadingMessages,
        currentMessagesList,
        canLoadMore,
        setCanLoadMore,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export const useMessageContext = () => {
  return useContext(MessageContext);
};
