// @mui
import { enUS, frFR, zhCN, viVN, arSD } from "@mui/material/locale";
import { PATH_DASHBOARD } from "../routes/paths";

// routes

export const config = {
  // API_BASE_URL: "https://dev.jcore.me/jf-chat-ws",
  // API_BASE_URL_CHAT: "https://dev.jcore.me/jf-chat/api/v1",
  // API_BASE_URL_CUSTOMER: "https://dev.jcore.me/jf-customer/api/v1",
  // API_BASE_URL_GENERAL_SETTING:
  //   "https://dev.jcore.me/jf-general-setting/api/v1",
  // API_BASE_URL_MEDIA: "https://dev.jcore.me/jf-media/api/v1",
  // API_BASE_URL_TERM: "https://dev.jcore.me/jf-term/api/v1",
  // ENV: "dev",
  // ACCESS_TOKEN:
  //   "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzaWduYXR1cmUiOiJ4ZFFpY3Qra2dwbC96YnBlSDY4WGoydWJxcEEvYk5VRkQ3SjdFTWJyb0Fqa0hkYzNoOW95YldXK3BhNmg3UVpZeUkyTWkxTkpQMEk4a3Z0eFhIb0ZXRXc1eDdWM0x6eGxGMzJFeWxtbDFGRmtSNlNlc3hKS2l4WkhKeW53ZjBmdytmQWtZL290NmZXUFp0UHFYdDIwUXdLazRTYkxQdFhmQXJZK2tVZjNnT0NXQjFzK3hWanh6dVRidHRPUEtxbnJ3Q052UTNZQUsxSHBJcFl4S0t2RzFyckdVNG5rZytsMit3N2V2RzZIWGtkdVlBZUtZSGxpNTc1dmZNdjFnY2MwakdQKzJPcEZyY3ZKV2loMFVJZmRnQkZrSm95bXBhL0ttVTZpRTJTbTUzS0JhNE94Nmp4S2JRdWZOLzNUVGordy8vS0xUT2tDWmo0dEcwRXAyOEt5VWc9PSIsImlhdCI6MTcwNjI1MzcwMywianRpIjoiMkY1OXc0c3BERlI5SjRtbkJieG9VIn0.1e9cIsAk0da8wARSC-4LqUcQzMOfyp4nTFoSMyOQhcV7Ci_TCSLSKfHHQBrKIQwVcAzcnAeMGNwXBMyZpeT2kw",
  // WS_API_BASE_PATH: "/jf-chat-ws/socket.io",
  // WS_API_BASE_URL: "https://dev.jcore.me",

  API_BASE_URL: "https://socket.jchat.io/jf-chat-ws",
  API_BASE_URL_CHAT: "https://socket.jchat.io/jf-chat/api/v1",
  API_BASE_URL_CUSTOMER: "https://socket.jchat.io/jf-customer/api/v1",
  API_BASE_URL_GENERAL_SETTING:
    "https://socket.jchat.io/jf-general-setting/api/v1",
  API_BASE_URL_MEDIA: "https://socket.jchat.io/jf-media/api/v1",
  ENV: "production",
  ACCESS_TOKEN:
    "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzaWduYXR1cmUiOiJxMnBmOGJNRkxVN3RzdE4vdlZIdTJncURrV2RPcUVwdzJKQVovbHh2T1NDc2F0cjZwQW0zQ3JncERWVU9ZT3VKT3RFMjhLS2V3Tkd2OE5VNS96U1RrSCtFY2MwSVp2UWlBZ2VMVDBmcUkvS01kdVlIZkFLNTcyN1hjMzYzb2g2TGl0RHNYbU9JdjE3aUNJb2lKLzVRT2lrNmxReS9Ib3c2bkRNZVVYbHlPcVRjTnhyQ0k3K1J6L2VmRTFOYVE4Y1FCekMwUkcwNzdmZ1hrZ0ZGZHY4MVUzbTg1NW5DbnZ6RmR1OGVTMllHK2FkNXRkUDBmYnJRNHpocVppbTViSnYxWWlIOVNKaDJVOWhHY094QkFKZ3BNQ3pRdGZ1RXRLMGlHR0FTbnIxbTBiTndXOGxZMElXVHpxM25mRFJzVGMwTDJGdFFDWGkvdUtEY3d0SGg3cFhqeEE9PSIsImlhdCI6MTcwNjA4OTMxMCwianRpIjoibWpDazhlOVBsMTBWM01zYUNqUWZuIn0.B6HYnDNZxII6olm5AZ2p9iPrT90rDzkStydALe3pJOwXPXLCnCGOAGdLf9n_tyaR2cQkGyIrKNpwlKKjYBbs0w",
  SRS_PUBLISH_SECRET: "9d0c780530de4c9c8db2bf06beeaa2eb",
  SRS_WEBRTC_SERVER_URL: "webrtc://srs.prod.jcore.me",
  WS_API_BASE_PATH: "/jf-chat-ws/socket.io",
  WS_API_BASE_URL: "https://socket.jchat.io",
};

export const defaultSettings = {
  themeMode: "light",
  themeDirection: "ltr",
  themeContrast: "default",
  themeLayout: "horizontal",
  themeColorPresets: "default",
  themeStretch: false,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const allLangs = [
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "/assets/icons/flags/ic_flag_en.svg",
  },
  {
    label: "French",
    value: "fr",
    systemValue: frFR,
    icon: "/assets/icons/flags/ic_flag_fr.svg",
  },
  {
    label: "Vietnamese",
    value: "vn",
    systemValue: viVN,
    icon: "/assets/icons/flags/ic_flag_vn.svg",
  },
  {
    label: "Chinese",
    value: "cn",
    systemValue: zhCN,
    icon: "/assets/icons/flags/ic_flag_cn.svg",
  },
  {
    label: "Arabic (Sudan)",
    value: "ar",
    systemValue: arSD,
    icon: "/assets/icons/flags/ic_flag_sa.svg",
  },
];

export const defaultLang = allLangs[0]; // English

// DEFAULT ROOT PATH
export const DEFAULT_PATH = PATH_DASHBOARD.general.app; // as '/app'
