import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChatWSAxios, Room, UserRoom } from "../services";
import { mergeArrays } from "../utils/array";

export const ChatContext = createContext<{
  rooms: Room[];
  setRooms: Dispatch<SetStateAction<Room[]>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  total: number;
  setTotal: Dispatch<SetStateAction<number>>;
  getRoomsFromAPI: (search: string) => void;
  canLoadMore: boolean;
  selectedRoom: Room | undefined;
  setSelectedRoom: Dispatch<SetStateAction<Room | undefined>>;
  selectedRoomMembers: Record<string, UserRoom>;
  selectedRoomCreatorUser: UserRoom | undefined;
}>({
  getRoomsFromAPI: () => {},
  isLoading: true,
  setIsLoading: () => {},
  rooms: [],
  setRooms: () => {},
  setTotal: () => {},
  total: 0,
  canLoadMore: false,
  selectedRoom: undefined,
  setSelectedRoom: () => {},
  selectedRoomMembers: {},
  selectedRoomCreatorUser: undefined,
});

export const ChatContextProvider = ({ children }: PropsWithChildren) => {
  const [rooms, setRooms] = useState<Room[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedRoom, setSelectedRoom] = useState<Room | undefined>(undefined);

  const searchRef = useRef("");

  const [total, setTotal] = useState(0);

  const getRoomsFromAPI = useCallback(
    async (search: string) => {
      try {
        setIsLoading(true);
        const result = await ChatWSAxios.getReq("/api/rooms/get-list", {
          params: {
            search,
            ...(!search ? { joined: true } : {}),
            limit: 10,
            offset: searchRef.current !== search ? 0 : rooms?.length ?? 0,
          },
        });

        setRooms(
          searchRef.current === search
            ? mergeArrays("id", rooms ?? [], result.rooms)
            : result.rooms
        );
        setTotal(result.total);
        searchRef.current = search;
      } catch (error) {
        // TODO: show error
      } finally {
        setIsLoading(false);
      }
    },
    [selectedRoom]
  );

  const canLoadMore = useMemo(() => {
    return !!rooms?.length && !!total && !(total <= (rooms?.length ?? 0));
  }, [rooms, total]);

  const selectedRoomMembers = useMemo(
    () =>
      (selectedRoom?.users ?? []).reduce(
        (pre, curr) => ({ ...pre, [curr.userId]: curr }),
        {}
      ),
    [selectedRoom]
  );

  const selectedRoomCreatorUser = useMemo(
    () =>
      selectedRoom
        ? (selectedRoom.users ?? []).find((item) => item.isCreator)
        : undefined,
    [selectedRoom]
  );

  useEffect(() => {
    return () => {
      setRooms([]);
      setIsLoading(true);
      setTotal(0);
      searchRef.current = "";
    };
  }, []);

  return (
    <ChatContext.Provider
      value={{
        rooms,
        setRooms,
        isLoading,
        setIsLoading,
        total,
        setTotal,
        getRoomsFromAPI,
        canLoadMore,
        selectedRoom,
        setSelectedRoom,
        selectedRoomMembers,
        selectedRoomCreatorUser,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  return useContext(ChatContext);
};
