// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import dayjs from "dayjs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThemeSettings from "./components/settings";
import {
  ChatContextProvider,
  ConnectionContextProvider,
  MessageContextProvider,
} from "./contexts";
import AuthContextProvider from "./contexts/AuthContext";
import OTPContextProvider from "./contexts/OTPContext";
import { SettingContextProvider } from "./contexts/SettingContext";

const relativeTime = require("dayjs/plugin/relativeTime");
// import relativeTime from 'dayjs/plugin/relativeTime' // ES 2015

dayjs.extend(relativeTime);

function App() {
  return (
    <>
      <ThemeProvider>
        <ThemeSettings>
          <SettingContextProvider>
            <OTPContextProvider>
              <AuthContextProvider>
                <ChatContextProvider>
                  <MessageContextProvider>
                    <ConnectionContextProvider>
                      <Router />
                    </ConnectionContextProvider>
                  </MessageContextProvider>
                </ChatContextProvider>
              </AuthContextProvider>
            </OTPContextProvider>
          </SettingContextProvider>
        </ThemeSettings>
      </ThemeProvider>
      <ToastContainer position="bottom-left" />
    </>
  );
}

export default App;
