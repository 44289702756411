import { HttpStatusCode } from "axios";
import {
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Socket, connect } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
// import LocalStorageKey from "../constants/local-storage-key";
// import {
//   Room,
//   UserMessage,
//   UserRoom,
//   useChatStore,
// } from "../libs/zustands/useChatStore";
// import { useKeysStore } from "../libs/zustands/useKeysStore";
// import { useRoomCallStore } from "../libs/zustands/useRoomCallStore";
import { ChatAxios, getRequestHeaders } from "../services/axios";
// import { RoomCall, UserRoomCallStatus } from "../types/room-call";
// import { UserStream } from "../types/streams";
import { config } from "../config";
import { LocalStorageKey, SocketEventTypeEnum } from "../enums";
import { Room, UserMessage, UserRoom } from "../services";
import { mergeArrays } from "../utils/array";
import { useAuthContext } from "./AuthContext";
import { useChatContext } from "./ChatContext";
import { useMessageContext } from "./MessageContext";
// import { ComputeRoomKeyRequest, useKeyExchange } from "./key-exchange";
// import { useStreamContext } from "./stream";
interface ChatMessageContent {
  content: string;
  created_at: string;
  creator_id: string;
  deleted_at: string;
  id: string;
  message_meta: string;
  message_type: string;
  updated_at: string;
  updater_id: string;
}

const ConnectionContext = createContext<{
  socketRef: RefObject<Socket> | null;
  socketReceivedData: any[];
  setSocketReceivedData: Dispatch<SetStateAction<any[]>>;
  sendSocketData: (data: any) => void;
  isSocketConnected: boolean;
}>({
  socketRef: null,
  socketReceivedData: [],
  setSocketReceivedData: () => {},
  sendSocketData: () => {},
  isSocketConnected: false,
});

export const ConnectionContextProvider = ({ children }: PropsWithChildren) => {
  const { userData } = useAuthContext();

  const [socketReceivedData, setSocketReceivedData] = useState<any[]>([]);
  const socketReceivedDataRef = useRef<typeof socketReceivedData>([]);

  const [isSocketConnected, setIsSocketConnected] = useState(false);
  // const { userKeyPair, roomEncryptKeys, setRoomEncryptKeys } = useKeysStore();
  const socketRef = useRef<Socket | null>(null);

  const { setRooms, rooms, selectedRoom, setSelectedRoom } = useChatContext();

  const {
    loadingMessages,
    messageListByRoom,
    setMessageListByRoom,
    setLoadingMessages,
  } = useMessageContext();

  // const { publicStreams, setPublicStreams, roomStreams, setRoomStreams } =
  //   useStreamContext();

  // const { roomCallAttendees, roomCalls, setRoomCalls, setRoomCallAttendees } =
  //   useRoomCallStore();

  // const { handleDoneGetShareKeyRoom, refRoomNeedToExchangeKey } =
  //   useKeyExchange();

  // const publicStreamsRef = useRef<typeof publicStreams>({});
  // const roomStreamsRef = useRef<typeof roomStreams>({});

  const messageListByRoomRef = useRef<typeof messageListByRoom>({});
  const roomListRef = useRef<Room[]>([]);

  // const userKeyPairRef = useRef<typeof userKeyPair>({
  //   private_key: "",
  //   public_key: "",
  // });
  const selectedRoomRef = useRef<typeof selectedRoom>();
  // const roomEncryptKeysRef = useRef<typeof roomEncryptKeys>({});

  // const roomCallsRef = useRef<typeof roomCalls>({});
  // const roomCallAttendeesRef = useRef<typeof roomCallAttendees>({});

  const loadingMessageRef = useRef<typeof loadingMessages>({});

  // useEffect(() => {
  //   publicStreamsRef.current = publicStreams;
  // }, [publicStreams]);

  // useEffect(() => {
  //   roomStreamsRef.current = roomStreams;
  // }, [roomStreams]);

  // useEffect(() => {
  //   userKeyPairRef.current = userKeyPair;
  // }, [userKeyPair]);

  // useEffect(() => {
  //   roomEncryptKeysRef.current = roomEncryptKeys;
  // }, [roomEncryptKeys]);

  useEffect(() => {
    selectedRoomRef.current = selectedRoom;
  }, [selectedRoom]);

  useEffect(() => {
    messageListByRoomRef.current = messageListByRoom;
  }, [messageListByRoom]);

  useEffect(() => {
    roomListRef.current = rooms ?? [];
  }, [rooms]);

  // useEffect(() => {
  //   roomCallsRef.current = roomCalls;
  // }, [roomCalls]);

  // useEffect(() => {
  //   roomCallAttendeesRef.current = roomCallAttendees;
  // }, [roomCallAttendees]);

  useEffect(() => {
    loadingMessageRef.current = loadingMessages;
  }, [loadingMessages]);

  useEffect(() => {
    socketReceivedDataRef.current = socketReceivedData;
  }, [socketReceivedData]);

  const navigate = useNavigate();

  const sendSocketData = ({ type, ...data }) => {
    if (socketRef.current) {
      socketRef.current.emit(type, data);
    }
  };

  const addDataToSocketReceivedData = (message) => {
    if (message) {
      const newData = [
        ...socketReceivedDataRef.current,
        { ...message, id: uuidv4() },
      ];
      setSocketReceivedData(newData);
    }
  };

  const [requestHeaders, setRequestHeaders] = useState(getRequestHeaders());

  useEffect(() => {
    const handleSocket = async () => {
      if (!userData) {
        socketRef.current = null;
      }
      const publicKey = JSON.parse(
        localStorage.getItem(LocalStorageKey.KeyPair) ?? "{}"
      ).public_key;
      if (!socketRef.current && userData && publicKey) {
        socketRef.current = connect(config.WS_API_BASE_URL, {
          extraHeaders: {
            ...getRequestHeaders(),
            "x-access-token": config.ACCESS_TOKEN,
          },
          auth: {
            "x-public-key": publicKey,
          },
          path: config.WS_API_BASE_PATH,
        });

        socketRef.current.on("connect", function () {
          if (socketRef.current?.connected) {
            setIsSocketConnected(true);
          }
        });

        socketRef.current.on("disconnect", function () {
          if (!socketRef.current?.connected) {
            setIsSocketConnected(false);
          }
        });

        socketRef.current.on(SocketEventTypeEnum.error, (message) => {
          if (message.status === HttpStatusCode.Unauthorized) {
            setRequestHeaders(getRequestHeaders());
          }
        });

        socketRef.current.on(
          SocketEventTypeEnum.removeRoom,
          (roomId: string) => {
            setRooms(roomListRef.current.filter((room) => room.id !== roomId));
            // const roomShareKey = { ...roomEncryptKeysRef.current };
            // delete roomShareKey[roomId];
            // setRoomEncryptKeys(roomShareKey);
            // localStorage.setItem(
            //   LocalStorageKey.RoomEncryptKey,
            //   JSON.stringify(roomShareKey)
            // );

            const roomMembers = JSON.parse(
              localStorage.getItem(LocalStorageKey.RoomMember) ?? "{}"
            );
            delete roomMembers[roomId];
            localStorage.setItem(
              LocalStorageKey.RoomMember,
              JSON.stringify(roomMembers)
            );

            if (selectedRoom?.id === roomId) {
              setSelectedRoom(undefined);
              navigate("/messages");
            }
          }
        );

        socketRef.current.on(
          SocketEventTypeEnum.memberChanged,
          (data: { roomId: string; memberList: UserRoom[] }) => {
            const { roomId, memberList } = data;

            if (
              selectedRoomRef.current &&
              selectedRoomRef.current.id === roomId
            ) {
              setSelectedRoom({
                ...selectedRoomRef.current,
                users: memberList,
              });
            }
            setRooms(
              roomListRef.current.map((room) =>
                room.id === roomId ? { ...room, users: memberList } : room
              )
            );
          }
        );

        // socketRef.current.on(
        //   SocketEventTypeEnum.requestPublicKey,
        //   ({ requestorSocketId, roomId }) => {
        //     if (socketRef && socketRef.current) {
        //       socketRef.current.emit(SocketEventTypeEnum.sendPublicKey, {
        //         publicKey: JSON.parse(
        //           localStorage.getItem(LocalStorageKey.KeyPair) ?? '{}',
        //         ).public_key,
        //         senderSocketId: socketRef.current.id,
        //         receiverSocketId: requestorSocketId,
        //         senderId: userData.id,
        //         roomId,
        //       });
        //     }
        //   },
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.streamStarted,
        //   (userStream: UserStream) => {
        //     if (userStream.userRoomId) {
        //       const newRoomStreams = {
        //         ...roomStreamsRef.current,
        //         [userStream.UserRoom.roomId]: (
        //           roomStreamsRef.current[userStream.UserRoom.roomId] ?? []
        //         ).filter((item) => item.id !== userStream.id),
        //       };
        //       if (userStream.isActive) {
        //         newRoomStreams[userStream.UserRoom.roomId] = (
        //           roomStreamsRef.current[userStream.UserRoom.roomId] ?? []
        //         ).concat(userStream);
        //       }
        //       setRoomStreams(newRoomStreams);
        //     } else {
        //       setPublicStreams({
        //         ...publicStreamsRef.current,
        //         [userStream.userId]: userStream,
        //       });
        //     }
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.streamEnded,
        //   (userStream: UserStream) => {
        //     if (userStream.userRoomId) {
        //       setRoomStreams({
        //         ...roomStreamsRef.current,
        //         [userStream.UserRoom.roomId]: (
        //           roomStreamsRef.current[userStream.UserRoom.roomId] ?? []
        //         ).filter((item) => item.id !== userStream.id),
        //       });
        //     } else {
        //       const newPublicStreams = { ...publicStreamsRef.current };
        //       delete newPublicStreams[userStream.userId];
        //       setPublicStreams(newPublicStreams);
        //     }
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.response_ComputeRoomKey,
        //   async (data: ComputeRoomKeyRequest) => {
        //     try {
        //       const newNextMembemPublicKeys =
        //         data.nextMemberPublicKeys.slice(1);
        //       let result;
        //       if (data.curve_params) {
        //         console.log("compute room key with curve params", data.roomId);
        //         result = await ChatAxios.postReq("/compute", {
        //           private_key: userKeyPairRef.current?.private_key,
        //           curve_params: data.curve_params,
        //         });
        //       } else {
        //         console.log(
        //           "compute room key without curve params",
        //           data.roomId
        //         );

        //         result = await ChatAxios.postReq("/compute", {
        //           private_key: userKeyPairRef.current?.private_key,
        //           public_key: data.ownerPublicKey,
        //         });
        //       }

        //       if (newNextMembemPublicKeys.length === 0) {
        //         console.log("handle send done shared key", data.roomId);
        //         socketRef.current?.emit(
        //           SocketEventTypeEnum.request_ComputeRoomKey_Done,
        //           {
        //             ...data,
        //             curve_params: result.data,
        //           }
        //         );
        //       } else {
        //         console.log("handle send result compute", data.roomId);
        //         socketRef.current?.emit(
        //           SocketEventTypeEnum.request_ComputeRoomKey,
        //           {
        //             ...data,
        //             nextMemberPublicKeys: newNextMembemPublicKeys,
        //             curve_params: result.data,
        //           } as ComputeRoomKeyRequest
        //         );
        //       }
        //     } catch (error) {
        //       console.log(getErrorMessage(error), error);
        //     }
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.response_ComputeRoomKey_Done,
        //   async (data: ComputeRoomKeyRequest) => {
        //     try {
        //       console.log("compute room key with curve params", data.roomId);
        //       const result = await ChatAxios.postReq("/compute", {
        //         private_key: userKeyPairRef.current?.private_key,
        //         curve_params: data.curve_params,
        //       });
        //       console.log("handle done get share key room", data.roomId);
        //       handleDoneGetShareKeyRoom(
        //         result.data,
        //         data.userPublicKeyList,
        //         data.roomKeyExchangeId,
        //         data.roomId,
        //         data.isOwner
        //       );
        //     } catch (error) {
        //       console.log(getErrorMessage(error), error);
        //     }
        //   }
        // );

        socketRef.current.on(
          SocketEventTypeEnum.newRoom,
          async (newRoom: Room) => {
            setRooms(mergeArrays("id", [newRoom], [...roomListRef.current]));
            // if (newRoom.encryptType === "key_exchange") {
            //   try {
            //     const roomNeedExchangeKey = await ChatWSAxios.getReq<Room>(
            //       "/api/room-key-exchanges/get-one-room-need-to-exchange-key/" +
            //         newRoom.id
            //     );

            //     // refRoomNeedToExchangeKey.current = mergeArrays(
            //     //   "id",
            //     //   [
            //     //     {
            //     //       room: roomNeedExchangeKey,
            //     //       isProcessing: false,
            //     //       id: roomNeedExchangeKey.id,
            //     //     },
            //     //   ],
            //     //   refRoomNeedToExchangeKey.current ?? []
            //     // );
            //   } catch (error) {
            //     console.log("Cannot get room need to exchange key", error);
            //   }
            // }
          }
        );

        socketRef.current.on(
          SocketEventTypeEnum.userRoomChanged,
          (newUserRoom) => {
            if (
              selectedRoomRef.current &&
              selectedRoomRef.current.id === newUserRoom.id
            ) {
              setSelectedRoom({
                ...selectedRoomRef.current,
                users: selectedRoomRef.current.users.map((item) =>
                  item.userId === newUserRoom.userId ? newUserRoom : item
                ),
              });
            }
          }
        );

        socketRef.current.on(
          SocketEventTypeEnum.messages,
          async (data: {
            messages: UserMessage[];
            roomId: string;
            count: number;
            limit: number;
            offset: number;
          }) => {
            let messageContents: Record<
              string,
              { content: string; messageMeta: string }
            > = {};
            let messagesToGetContent = data.messages
              .filter(
                (item) => !item.message.content && !item.message.messageMeta
              )
              .map((item) => item.message.id);
            messagesToGetContent = messagesToGetContent.concat(
              data.messages.reduce((pre, curr) => {
                return (pre ?? []).concat(
                  (curr.threadMessages ?? [])
                    ?.filter(
                      (item) =>
                        !item.message.content && !item.message.messageMeta
                    )
                    .map((item) => item.message.id) ?? []
                );
              }, [] as string[])
            );
            try {
              const messages = await ChatAxios.postReq<{
                data: ChatMessageContent[];
              }>("/chat/get-list-message", {
                id_list: messagesToGetContent,
              });
              messageContents = messages.data.reduce(
                (pre, curr) => ({
                  ...pre,
                  [curr.id]: {
                    content: curr.content,
                    messageMeta: curr.message_meta,
                  },
                }),
                {}
              );
            } catch (error) {
              console.log("cannot get message content");
            }
            setMessageListByRoom({
              ...messageListByRoomRef.current,
              [data.roomId]: mergeArrays(
                "id",
                (messageListByRoomRef.current[data.roomId] ?? []).slice(
                  0,
                  data.offset
                ),
                data.messages.map((item) => {
                  if (!messageContents[item.message.id]) {
                    return item;
                  }
                  return {
                    ...item,
                    message: {
                      ...item.message,
                      content:
                        messageContents[item.message.id].content ??
                        item.message.content,
                      messageMeta:
                        messageContents[item.message.id].messageMeta ??
                        item.message.messageMeta,
                    },
                  };
                })
              ),
            });

            addDataToSocketReceivedData(data);
            setLoadingMessages({
              ...loadingMessageRef.current,
              [data.roomId]: false,
            });
          }
        );

        socketRef.current.on(
          SocketEventTypeEnum.messageAdded,
          (newMessage: UserMessage) => {
            const roomId = newMessage.roomId;
            if (roomId === selectedRoomRef.current?.id) {
              const messageListByRoom = [
                ...(messageListByRoomRef.current[roomId] ?? []),
              ];
              if (newMessage.threadId) {
                setMessageListByRoom({
                  ...messageListByRoomRef.current,
                  [roomId]: messageListByRoom.map((item) =>
                    newMessage.threadId === item.id
                      ? {
                          ...item,
                          threadMessages: mergeArrays(
                            "id",
                            [newMessage],
                            item.threadMessages ?? []
                          ),
                        }
                      : item
                  ),
                });
              } else {
                setMessageListByRoom({
                  ...messageListByRoomRef.current,
                  [roomId]: mergeArrays("id", [newMessage], messageListByRoom),
                });
              }
            }
          }
        );
        socketRef.current.on(
          SocketEventTypeEnum.offer,
          addDataToSocketReceivedData
        );

        socketRef.current.on(
          SocketEventTypeEnum.answer,
          addDataToSocketReceivedData
        );

        socketRef.current.on(
          SocketEventTypeEnum.candidate,
          addDataToSocketReceivedData
        );

        socketRef.current.on("connect_error", (err) => {
          console.log("connect_error", JSON.stringify(err));
        });

        // socketRef.current.on(
        //   SocketEventTypeEnum.response_RoomMember_Online,
        //   (data: { roomId: string; deviceInfo: ConnectedDevice }) => {
        //     handleAddOnlineMember(data.roomId, data.deviceInfo);
        //   },
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.response_RoomMember_Offline,
        //   (data: { roomId: string; user }) => {
        //     handleRemoveOnlineMember(data.roomId);
        //   },
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.roomCallStarted,
        //   ({ roomCall, roomId }: { roomCall: RoomCall; roomId: string }) => {
        //     setRoomCalls({
        //       ...roomCallsRef.current,
        //       [roomId]: roomCall,
        //     });
        //     setRoomCallAttendees({
        //       ...roomCallAttendeesRef.current,
        //       [roomCall.id]: [],
        //     });
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.roomCallEnded,
        //   (roomCall: RoomCall) => {
        //     const newRoomCalls = { ...roomCallsRef.current };
        //     if (newRoomCalls[roomCall.roomId]) {
        //       delete newRoomCalls[roomCall.roomId];
        //       setRoomCalls(newRoomCalls);
        //     }

        //     const newRoomCallAttendees = {
        //       ...roomCallAttendeesRef.current,
        //     };
        //     delete newRoomCallAttendees[roomCall.id];
        //     setRoomCallAttendees(newRoomCallAttendees);
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.roomCallReceiveJoinRequest,
        //   ({
        //     roomCall,
        //     joinUserRoomCallStatus,
        //   }: {
        //     roomCall: RoomCall;
        //     joinUserRoomCallStatus: UserRoomCallStatus;
        //   }) => {
        //     const newRoomCallAttendees = {
        //       ...roomCallAttendeesRef.current,
        //     };

        //     newRoomCallAttendees[roomCall.id] = mergeArrays(
        //       "id",
        //       [joinUserRoomCallStatus],
        //       newRoomCallAttendees[roomCall.id] ?? []
        //     );
        //     setRoomCallAttendees(newRoomCallAttendees);
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.roomCallReceiveLeaveRequest,
        //   ({
        //     roomCall,
        //     leaveUserRoomCallStatus,
        //   }: {
        //     roomCall: RoomCall;
        //     leaveUserRoomCallStatus: UserRoomCallStatus;
        //   }) => {
        //     const newRoomCallAttendees = {
        //       ...roomCallAttendeesRef.current,
        //     };
        //     newRoomCallAttendees[roomCall.id] = (
        //       newRoomCallAttendees[roomCall.id] ?? []
        //     ).filter((item) => item.id !== leaveUserRoomCallStatus.id);

        //     setRoomCallAttendees(newRoomCallAttendees);
        //   }
        // );
      }
    };
    handleSocket();
  }, [userData, requestHeaders]);

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.emit(SocketEventTypeEnum.roomCallDisconnectedAll);
        socketRef.current.disconnect();
      }
    };
  }, []);

  return (
    <ConnectionContext.Provider
      value={{
        socketRef,
        setSocketReceivedData,
        socketReceivedData,
        sendSocketData,
        isSocketConnected,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnectionContext = () => {
  return useContext(ConnectionContext);
};
